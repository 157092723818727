import {axiosClient} from '../api/httpClient';
import * as Types from './types';

export const fetchUser = () => async dispatch => {
    try {
        dispatch({type: Types.FETCHING_NOTIFICATION_LIST});
        const resList = await axiosClient.get('/api/notifications');
        dispatch({type: Types.FETCH_NOTIFICATION_LIST, payload: resList.data.notifications});

        dispatch({type: Types.FETCHING_NOTIFICATION_COUNT});
        const resCount = await axiosClient.get('/api/notifications/getCount');
        dispatch({type: Types.FETCH_NOTIFICATION_COUNT, payload: resCount.data.notificationsCount});

        dispatch({type: Types.FETCHING_USER});
        const res = await axiosClient.get('/api/me');
        dispatch({type: Types.FETCH_USER, payload: res});
    } catch (e) {
        dispatch({type: Types.FETCH_USER, payload: e.response});
    }
};

export const unSeenMessages = () => async dispatch => {
    try {
        axiosClient.get('/api/inbox/unseen')
        .then(response => {
            dispatch({type: Types.UNSEEN_INBOX_MESSAGE, payload: response});
        }).catch(err => {
            console.error(err);
            dispatch({type: Types.UNSEEN_INBOX_MESSAGE, payload: err.response});
        });
    } catch (e) {
        dispatch({type: Types.UNSEEN_INBOX_MESSAGE, payload: e.response});
    }
};

export const fetchSubDomain = () => async dispatch => {
    try {
        dispatch({type: Types.FETCHING_SUB_DOMAIN_DATA})
        const res = await axiosClient.get('/api/white-label-customer-info/'+ window.location.host)
        dispatch({type: Types.FETCH_SUB_DOMAIN_DATA, payload: res});
    } catch (e) {
        dispatch({type: Types.FETCH_SUB_DOMAIN_DATA, payload: e.response});
    }
};

export const logout = () => async dispatch => {
    try {
        localStorage.removeItem('access');
        localStorage.removeItem("skipProfileForNow");
        localStorage.removeItem("Authorization");
        localStorage.removeItem("timezone");
        localStorage.removeItem("parentId");
        const res = await axiosClient.post('/api/logout');
        dispatch({type: Types.USER_LOGOUT, payload: res});
    } catch (e) {
        dispatch({type: Types.USER_LOGOUT, payload: e.response});
    }
};

export const fetchCards = (obj,viewAs = null) => async dispatch => {
    try {
        dispatch({type: Types.CARDS_FETCHING});
        let params = viewAs ? {
            view_as: viewAs
        } : {};
        let newObj = obj ? obj:{
            offset : 0,
            limit:10,
            section : "",
            card_name : ""
        }
        //const res = await axiosClient.get('/api/cards', { params });
        const res = await axiosClient.post("/api/getCards",{...params,...newObj});
        dispatch({type: Types.CARDS_FETCHED, payload: res});
    }
    catch (e) {
        dispatch({type: Types.CARDS_FETCHED, payload: e.response});
    }
};

export const fetchYtHelperCards = (obj,viewAs = null) => async dispatch => {
    try {
        dispatch({type: Types.YTHELPER_CARDS_FETCHING});
        let params = viewAs ? {
            view_as: viewAs
        } : {};
        let newObj = obj ? obj:{
            offset : 0,
            limit:10,
            section : "",
        }
        const res = await axiosClient.post("/api/ythelper/cards",{...params,...newObj});
        dispatch({type: Types.YTHELPER_CARDS_FETCHED, payload: res});
    }
    catch (e) {
        dispatch({type: Types.YTHELPER_CARDS_FETCHED, payload: e.response});
    }
};

export const fetchAllUsers = () => async dispatch => {
    try {
        dispatch({type: Types.FETCHING_ALL_USERS});
        const res = await axiosClient.get('/api/users');
        dispatch({type: Types.FETCH_ALL_USERS, payload: res});
    } catch (e) {
        dispatch({type: Types.FETCH_ALL_USERS, payload: e.response});
    }
};

export const fetchAllCustomers = () => async dispatch => {
    try {
        dispatch({type: Types.FETCHING_ALL_CUSTOMERS});
        const res = await axiosClient.get('/api/customers');
        dispatch({type: Types.FETCH_ALL_CUSTOMERS, payload: res});
    } catch (e) {
        dispatch({type: Types.FETCH_ALL_CUSTOMERS, payload: e.response});
    }
};

export const fetchAllWhiteLabelCustomers = () => async dispatch => {
    try {
        dispatch({type: Types.FETCHING_ALL_WHITE_LABEL_CUSTOMERS});
        const res = await axiosClient.get('/api/white-label-customers');
        dispatch({type: Types.FETCH_ALL_WHITE_LABEL_CUSTOMERS, payload: res});
    } catch (e) {
        dispatch({type: Types.FETCH_ALL_WHITE_LABEL_CUSTOMERS, payload: e.response});
    }
};

export const addUser = (user) => dispatch => {
    dispatch({type: Types.ADD_USER, payload: user});
};

export const addCustomer = (user) => dispatch => {
    dispatch({type: Types.ADD_CUSTOMER, payload: user});
};

export const searchUser = (search) => dispatch =>  {
    dispatch({type: Types.SEARCH_USER,payload: search});
 }
 
export const removeUser = (user) => dispatch => {
    dispatch({type: Types.REMOVE_USER, payload: user});
};

export const removeCustomer = (user) => dispatch => {
    dispatch({type: Types.REMOVE_CUSTOMER, payload: user});
};

export const removeWhiteLabelCustomer = (user) => dispatch => {
    dispatch({type: Types.REMOVE_WHITE_LABEL_CUSTOMER, payload: user});
};

export const fetchSettings = () => async dispatch => {
    try {
        dispatch({type: Types.FETCHING_SETTINGS});
        const res = await axiosClient.get('/api/portal/settings/V2');
        dispatch({type: Types.FETCH_SETTINGS, payload: res});
    } catch (e) {
        dispatch({type: Types.FETCH_SETTINGS, payload: e.response});
    }
};

export const updateSettings = (body) => dispatch => {
    dispatch({type: Types.UPDATE_SETTINGS, payload: body});
};

export const fetchAllWebsiteUsers = () => async dispatch => {
    try {
        const res = await axiosClient.get('/api/getWebsiteUser');
        dispatch({type: Types.FETCH_WEBSITE_USER, payload: res.data});
    } catch (e) {
        // dispatch({type: Types.FETCH_WEBSITE_USER, payload: e.response});
    }
};
