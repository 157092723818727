import React, {useEffect, useState} from "react";
import {connect} from "react-redux";
import {Redirect, useParams, useHistory} from "react-router-dom";
import {
    Button,
    Card,
    Col,
    Container,
    DatePicker,
    Divider,
    Icon,
    Row,
    Select,
    Textarea,
    TextInput,
    Checkbox,
    ProgressBar,
    Tab,
    Tabs,
} from "react-materialize";
import {axiosClient} from "../api/httpClient";
import {
    errorToast,
    finishToast,
    getUserOptions,
    infoToast,
    isAdmin,
    isSubAdmin,
    isCustomer,
    modifiedOrNull,
    parseSqlDateTime,
    successToast,
    toISODate,
    updateToast,
    resize,
    uploadMedia,
    getUserShortName
} from "../api/Util";
import * as _ from 'lodash';
import ConfirmationDialog from "./ConfirmationDialog";
import BillingTable from "./common/BillingTable/BillingTable";
import {
    CARD_COMMENT_EMAIL,
    CARD_COMMENT_TAG_EMAIL,
    CARD_MOVEMENT_EMAIL,
    CUSTOM,
    CUSTOMER,
    DOUBLE,
    INITIAL_EMAIL_PREFERENCES_STATE,
    SINGLE,
    TIMEZONE_LIST,
    UNLIMITED,
    WEEKLY,
    YT_HELPER,
    YT_HELPER_LEAD
} from "../api/Constants";
import {randomNumber} from "react-ratings-declarative/build/utils";


const INITIAL_STATE = {
    firstName: "",
    lastName: "",
    email: "",
    secondaryEmail: "",
    type: "",
    dropboxEmail: "",
    password: "",
    teamLead: "0",
    assignQa: ["0", "0"],
    userProfile: {},
    billingDate: new Date(0),
    billingData: [],
    doneVideoLimit:1,
    subscriptionType: SINGLE,
    customSubscription: "",
    subtitles: "0",
    hasYoutubeHelper: "0",
    teamNote: "",
    youtubeHelper: "0",
    preferredEditingSoftware: "",
    timezone: "",
    referral: "Other",
    otherReferral: "",
    videoType: "",
    aboutVideo: "",
    socialMediaLink: "",
    videoStyle: "",
    videoInspired: "",
    videoInfo: "",
    additionalVideoInfo: "",

    channelName:"",
    prevThumbnailsLink:[""],
    refThumbnailsFile:"",
    thumbnailFont:"",
    thumbnailMainColor:"",
    videoPublishingSchedules: "",
    thumbnailSubColor:"",
    descriptionLink:[""],
    photoPreference:"",
    textPreference:"",
    titlePreference:"",
    endCardPreference:"",
    publicVideo:"",
    keywordList:"",
    additionalThumbnailData:"",
};

export default connect(mapStateToProps)((props) => {
    const {userId} = useParams();
    const history = useHistory();

    if(props?.auth?.loggedInUser?.client_id !== userId && isCustomer(props?.auth?.loggedInUser)) {
        return <Redirect to="/" />
    }
    const cantEdit = isSubAdmin(props?.auth?.loggedInUser) ? (!isSubAdmin(props?.auth?.loggedInUser)) : (!isAdmin(props?.auth?.loggedInUser) && userId !== props?.auth?.loggedInUser?.client_id);
    const [state, setState]
        = useState(INITIAL_STATE);
    const {firstName, lastName, email, password, secondaryEmail, type, teamLead, dropboxEmail, userProfile, assignQa,
        billingDate, billingData, doneVideoLimit: doneVideoNo, subscriptionType, customSubscription, subtitles, hasYoutubeHelper, youtubeHelper, teamNote,
        preferredEditingSoftware, timezone} = state;
    const [updateDisabled, setUpdateDisabled] = useState(false);
    const [confirmation, setConfirmation] = useState(false);

    const [selectedFile, setSelectedFile] = useState(null);
    const [showThumbnailsPre, setShowThumbnailsPre] = useState(false);
    const [activeTab, setActiveTab] = useState('BASIC INFO');

    const refreshUser = () => {
        const toastId = infoToast("Loading user data", 0.0);
        axiosClient.get('/api/user/' + userId, {
            onDownloadProgress: (pE) => updateToast(toastId.current, pE.loaded / (pE.total || 10000))
        }).then(response => {
            let user = response.data;
            finishToast(toastId.current);

            let ytHelper = (user.assignments || [])
                .filter(assignment => assignment.client_type === YT_HELPER || assignment.client_type === YT_HELPER_LEAD)
                .map(assignment => `${assignment.client_id}`)
                // .concat(Array(1).fill("0")).slice(0,1)[0];
                .concat(Array(2).fill("0")).slice(0,2);

            setState({
                firstName: user.firstname,
                lastName: user.lastname,
                email: user.useremail,
                secondaryEmail: user.secondary_email || "",
                type: user.client_type,
                dropboxEmail: user.dropbox_email || "",
                password: "",
                teamLead: `${user.assigned_to_lead_id}`,
                userProfile: user,
                assignQa: (user.qas || []).map(qa => `${qa.client_id}`).concat(Array(3).fill("0")).slice(0,3),
                billingDate: parseSqlDateTime(user.billing_date),
                billingData: user.billing_data,
                subscriptionType: user.subscription_type,
                customSubscription: user.custom_subscription,
                subtitles: `${user.has_subtitles}`,
                hasYoutubeHelper: `${user.has_youtube_helper}`,
                youtubeHelper: ytHelper,
                teamNote: user.team_notes,
                preferredEditingSoftware: user.preferred_editing_software || "",
                timezone: user.timezone || "",
                referral: user.referral,
                otherReferral: user.otherreferral,
                videoType: user.video_type,
                aboutVideo: user.about_video,
                socialMediaLink: user.social_media_link,
                videoStyle: user.video_style,
                videoInspired: user.video_inspired,
                videoInfo: user.video_info,
                additionalVideoInfo: user.additional_video_info,
                doneVideoLimit: user.done_video_limit,

                // for yt helper fields data
                channelName: user.channel_name ,
                prevThumbnailsLink: user.prev_thumbnails_link && user.prev_thumbnails_link !== "" ? user.prev_thumbnails_link.split('|'):[""],
                refThumbnailsFile: user.ref_thumbnails_file,
                thumbnailFont: user.thumbnail_font,
                thumbnailMainColor: user.thumbnail_main_color,
                videoPublishingSchedules: user.video_publishing_schedules,
                thumbnailSubColor: user.thumbnail_sub_color,
                descriptionLink: user.description_link && user.description_link !== "" ? user.description_link.split('|'):[""],
                photoPreference: user.photo_preference,
                textPreference: user.text_preference,
                titlePreference: user.title_preference,
                endCardPreference: user.end_card_preference,
                publicVideo: user.public_video,
                keywordList:user.keyword_list,
                additionalThumbnailData:user.additional_thumbnail_data,
            });
            setState(prev => ({ ...prev, type: user.client_type}));

            if (user.mailPreferences) {
                setState(prev => ({...prev, mailPreferences: user.mailPreferences}));
            }

            if(user.ref_thumbnails_file && user.ref_thumbnails_file !== "")
            {
                setShowThumbnailsPre(true);
            }
        }).catch(err => {
            errorToast(`Unable to load user: ${err.message}`);
            console.error(err);
        });
    };

    useEffect(() => {
        if (!props.users.users && !props.users.loadingUsers) {
            props.fetchAllUsers();
            return (
                <ProgressBar/>
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    
    useEffect(() => {
        setState(INITIAL_STATE);
        refreshUser();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userId]);

    function getQaAndTeamLead(users){
        return ([...users,props?.auth?.loggedInUser] || []).filter(user => user.client_type === "qa" || 
            user.client_type === "team-lead").map((user) => (
                <option key={`${user.client_type}-${user.client_id}`} value={`${user.client_id}`}>
                    {user.fullname ? getUserShortName(user.fullname) : `${getUserShortName(user.firstname, user.lastname)}`}
                </option>
            ));
    };

    function onClickUpdate() {
        setConfirmation(true);
    }
console.log(state)
    async function updateUser() {
        setUpdateDisabled(true);
        let data = {
            firstname: modifiedOrNull(firstName, userProfile.firstname),
            lastname: modifiedOrNull(lastName, userProfile.lastname),
            email: modifiedOrNull(email, userProfile.useremail),
            secondary_email: modifiedOrNull(secondaryEmail, userProfile.secondary_email),
            client_type: modifiedOrNull(type, userProfile.client_type),
            dropbox_email: modifiedOrNull(dropboxEmail, userProfile.dropbox_email),
            password: (password !== null && password !== "") ? password : null,
        };
        let extras;
        if(isCustomer(userProfile)) {
            extras = {
                assign_qa: assignQa.filter(qa => qa && qa !== "0").map(Number),
                billing_date: modifiedOrNull(toISODate(billingDate), userProfile.billing_date),
                subscription_type: modifiedOrNull(subscriptionType, userProfile.subscription_type),
                custom_subscription: modifiedOrNull(customSubscription, userProfile.custom_subscription),
                has_subtitles: modifiedOrNull(subtitles, `${userProfile.has_subtitles}`),
                has_youtube_helper: modifiedOrNull(hasYoutubeHelper, `${userProfile.has_youtube_helper}`),
                assign_yt_helper: youtubeHelper.filter(yt => yt && yt !== "0").map(Number),
                team_note: modifiedOrNull(teamNote, userProfile.team_note),
                preferred_editing_software: modifiedOrNull(preferredEditingSoftware, userProfile.preferred_editing_software),
                timezone: modifiedOrNull(timezone, userProfile.timezone),
                referral: modifiedOrNull(state.referral, userProfile.referral),
                otherreferral: modifiedOrNull(state.otherReferral, userProfile.otherreferral),
                video_type: modifiedOrNull(state.videoType, userProfile.video_type),
                about_video:  modifiedOrNull(state.aboutVideo, userProfile.about_video),
                social_media_link:  modifiedOrNull(state.socialMediaLink, userProfile.social_media_link),
                video_style:  modifiedOrNull(state.videoStyle, userProfile.video_style),
                video_inspired:  modifiedOrNull(state.videoInspired, userProfile.video_inspired),
                video_info:  modifiedOrNull(state.videoInfo, userProfile.video_info),
                additional_video_info:  modifiedOrNull(state.additionalVideoInfo, userProfile.additional_video_info),
                done_video_limit: modifiedOrNull(state.doneVideoLimit,userProfile.done_video_limit),

                // for yt helper fields data
                channel_name: modifiedOrNull(state.channelName, userProfile.channel_name),
                prev_thumbnails_link: modifiedOrNull(state.prevThumbnailsLink, userProfile.prev_thumbnails_link),
                ref_thumbnails_file: modifiedOrNull(state.refThumbnailsFile, userProfile.ref_thumbnails_file),
                thumbnail_font: modifiedOrNull(state.thumbnailFont, userProfile.thumbnail_font),
                thumbnail_main_color: modifiedOrNull(state.thumbnailMainColor, userProfile.thumbnail_main_color),
                thumbnail_sub_color: modifiedOrNull(state.thumbnailSubColor, userProfile.thumbnail_sub_color),
                description_link: modifiedOrNull(state.descriptionLink, userProfile.description_link),
                photo_preference: modifiedOrNull(state.photoPreference, userProfile.photo_preference),
                text_preference: modifiedOrNull(state.textPreference, userProfile.text_preference),
                title_preference: modifiedOrNull(state.titlePreference, userProfile.title_preference),
                end_card_preference: modifiedOrNull(state.endCardPreference, userProfile.end_card_preference),
                public_video: modifiedOrNull(state.publicVideo, userProfile.public_video),
                keyword_list:modifiedOrNull(state.keywordList, userProfile.keyword_list),
                additional_thumbnail_data:modifiedOrNull(state.additionalThumbnailData, userProfile.additional_thumbnail_data),
            };
            if (state.mailPreferences) {
                extras = {...extras, mailPreferences: state.mailPreferences};
            }
        } else {
            extras = {
                assigned_to_lead_id: teamLead,
            };
        }
        let secondary_email = data.secondary_email;
        data = _.assign({}, data, extras);
        data = _.pickBy(data, _.identity);

        data = {...data, secondary_email, updatedFromProfile:true, video_publishing_schedules: modifiedOrNull(state.videoPublishingSchedules, userProfile.video_publishing_schedules)};

        axiosClient.put('/api/user/' + userId, data).then(() => {
            successToast(`User ${userProfile.firstname} ${userProfile.lastname} updated`);
            history.goBack();
            props.fetchAllUsers();
        }).catch(err => {
            errorToast("Unable to update user: " + err.message);
        }).finally(() => {
            setUpdateDisabled(false);
            setConfirmation(false);
        });
    }

    function onChange(e) {
        const {name, value} = e.target;
        setState(prev => ({...prev, [name]: value}));
    }

    function onEmailPreferencesChange(e) {
        const { name } = e.target;
        let mailPreferences = state.mailPreferences ? state.mailPreferences : INITIAL_EMAIL_PREFERENCES_STATE;
        mailPreferences[name] = mailPreferences[name] === 1 ? 0:1;
        setState(prev => ({...prev, mailPreferences}));
    }

    function onChangeQa(e, index) {
        const value = e.target.value;
        let qas = [...assignQa];
        qas[index] = value;
        setState(prev => ({...prev, assignQa: qas}));
    }

    function onChangeYt(e, index) {
        const value = e.target.value;
        let yts = [...youtubeHelper];
        yts[index] = value;
        setState(prev => ({...prev, youtubeHelper: yts}));
    }

    function getSelectOptions() {
        return Array(100).fill().map((v,i)=>(
            <option key={`${i+1}-${i+1}`} value={`${i+1}`}>
                {`${i+1}`}
            </option>
        ));
    }

    function makeLinks(name, value, onRemove) {
        if(value.length)
        {
            let links = Array(value.length - 1);
            for(let id = 1; id < value.length; id++) {
                links[id-1] = (
                    <TextInput key={`txt_${name}-${id}`} s={12} name={name} type="url" validate disabled={cantEdit} onChange={e => onChangeLinks(e, id)} value={value[id]}
                    icon={<Button disabled={cantEdit} style={{marginRight: "10px"}} className="btn-danger" onClick={() => onRemove(id)}><Icon>remove</Icon></Button>} />
                );
            }
            return links;
        }
    }

    function removePrevThumbnailsLink(id) {
        let list = state.prevThumbnailsLink;
        list.splice(id, 1);
        setState(prev => ({...prev, prevThumbnailsLink: list}));
    }
    
    function removeDescriptionLink(id) {
        let list = state.descriptionLink;
        list.splice(id, 1);
        setState(prev => ({...prev, descriptionLink: list}));
    }

    function onChangeLinks(e, index) {
        let name = e.target.name;
        let value = e.target.value;
        
        if(index !== null && index >= 0) {
            let list = state[name];
            list = resize(list, index, "");
            list[index] = value;
            setState(prevState => ({...prevState, [name]: list}));
        }
    }

    function addPrevThumbnailsLink() {
        setState(prev => ({...prev, prevThumbnailsLink: [...prev.prevThumbnailsLink, ""]}));
    }
    function addDescriptionLink() {
        setState(prev => ({...prev, descriptionLink: [...prev.descriptionLink, ""]}));
    }
    async function uploadFile() {
        try {
            if (selectedFile) {
                let url = null;
                const progressBar = infoToast("Uploading file, please wait", 0);

                if(state.refThumbnailsFile && state.refThumbnailsFile !== ""){
                    await axiosClient.post('/api/delete/media',{
                        url: state.refThumbnailsFile
                    })
                }

                url = await fetch(selectedFile)
                    .then(r => r.blob())
                    .then(blob => uploadMedia(blob, userId, progressBar));
                // eslint-disable-next-line no-useless-computed-key
                setState(prev => ({...prev, ["refThumbnailsFile"]: url}));
                
                await axiosClient.post('/api/user/uploadThumbnailFile', {
                    ref_thumbnails_file: url || '',
                    client_id:userId
                });

                successToast("File updated successfully");
                setShowThumbnailsPre(true);
                setSelectedFile(null);
            }
            
        } catch(err) {
            console.error(err);
            errorToast("Something went wrong in uploading");
        }
    }

    function handleActiveTab() {
        const tag = document.getElementsByClassName('active')[1]?.innerText;
        setActiveTab(tag);
    }

    return (
        <Card id="user_profile_page" actions={activeTab === "BASIC INFO" ? [
            <Button key="refresh-btn" onClick={e => refreshUser(e)} node="button" waves="green" large flat>
                Reload <Icon right>refresh</Icon>
            </Button>,
            <Button key="update-btn" onClick={() => onClickUpdate()} disabled={updateDisabled} node="button" waves="green" className="btn-primary" large>
                Update <Icon right>save</Icon>
            </Button>
        ] : null}>
            <Tabs className="user-tabs" options={{ responsiveThreshold: Infinity }} onChange={(e) => handleActiveTab(e.target)}>
                <Tab title="Basic Info">
                    <Container id="customer-tabs-container">
                        <ConfirmationDialog
                            onNegative={() => setConfirmation(false)}
                            onPositive={updateUser}
                            confirmationHeader="Confirmation"
                            confirmationDialogId="userUpdateConfirmation"
                            confirmation={confirmation}
                            confirmationText="Are you sure you want to update this customer profile?"
                        />
                        <Row>
                            <TextInput s={12} m={6} id="txtFirstName" name="firstName" label="First Name" icon="person"
                                    value={firstName} onChange={onChange} disabled={cantEdit}/>
                            <TextInput s={12} m={6} id="txtLastName" name="lastName" label="Last Name" icon="person"
                                    value={lastName} onChange={onChange} disabled={cantEdit}/>
                        </Row>
                        <Row>
                            <TextInput s={12} m={6} id="txtEmail" name="email" label="Email" email validate icon="email"
                                    value={email} onChange={onChange} disabled={cantEdit}/>
                            <TextInput s={12} m={6} id="txtPassword" name="password" label="Password" type="password" icon="lock"
                                    value={password} onChange={onChange} disabled={cantEdit}/>
                        </Row>
                        <Row>
                            <TextInput s={12} m={6} id="txtSecondaryEmail" name="secondaryEmail" label="Assistant Email" email validate icon="email"
                                    value={secondaryEmail} onChange={onChange} disabled={cantEdit}/>
                                    {/* Change from "What is your Dropbox Email" */}
                            <TextInput s={12} m={6} id="txtDropboxEmail" name="dropboxEmail" label="What is your Gdrive Email?" icon="email"
                                    value={dropboxEmail} onChange={onChange} email validate disabled={cantEdit}/>
                        </Row>
                        <Row>
                            <Select s={12} m={6} disabled={isSubAdmin(props?.auth?.loggedInUser) ? true : cantEdit}
                                    icon={<Icon>supervisor_account</Icon>}
                                    id="assignQa"
                                    label="Assign QA"
                                    value={assignQa[0]}
                                    onChange={e => onChangeQa(e, 0)}>
                                <option value="0">
                                    None
                                </option>
                                {getQaAndTeamLead(props.users.users)}
                            </Select>
                            <Select s={12} m={6} disabled={isSubAdmin(props?.auth?.loggedInUser) ? true : cantEdit}
                                    icon={<Icon>supervisor_account</Icon>}
                                    id="assignQa2"
                                    label="Assign QA"
                                    value={assignQa[1]}
                                    onChange={e => onChangeQa(e, 1)}>
                                <option value="0">
                                    None
                                </option>
                                {getQaAndTeamLead(props.users.users)}
                            </Select>
                            <Select s={12} m={6} disabled={isSubAdmin(props?.auth?.loggedInUser) ? true : cantEdit}
                                    icon={<Icon>supervisor_account</Icon>}
                                    id="assignQa3"
                                    label="Assign QA"
                                    value={assignQa[2]}
                                    onChange={e => onChangeQa(e, 2)}>
                                <option value="0">
                                    None
                                </option>
                                {getQaAndTeamLead(props.users.users)}
                            </Select>
                            <Select s={12} m={6} disabled={cantEdit}
                                    icon={<Icon>assignment</Icon>}
                                    id="doneVideoLimit"
                                    name="doneVideoLimit"
                                    label="Done video limit"
                                    value={doneVideoNo.toString()}
                                    onChange={onChange}>
                                <option value="1">
                                    1
                                </option>
                                <option value="2">
                                    2
                                </option>
                                <option value="3">
                                    3
                                </option>
                                <option value="4">
                                    4
                                </option>
                            </Select>
                        </Row>
                        {
                            type === CUSTOMER ?
                                React.Children.toArray([<Row>
                                    <Select s={12} m={6} disabled={isSubAdmin(props?.auth?.loggedInUser) ? true : cantEdit}
                                            icon={<Icon>assignment</Icon>}
                                            id="subscriptionType"
                                            name="subscriptionType"
                                            label="Subscription Type"
                                            value={subscriptionType}
                                            onChange={onChange}>
                                        <option value="">
                                            None
                                        </option>
                                        <option value={SINGLE}>
                                            {SINGLE}
                                        </option>
                                        <option value={WEEKLY}>
                                            {WEEKLY}
                                        </option>
                                        <option value={UNLIMITED}>
                                            {UNLIMITED}
                                        </option>
                                        <option value={DOUBLE}>
                                            {DOUBLE}
                                        </option>
                                        <option value={CUSTOM}>
                                            {CUSTOM}
                                        </option>
                                    </Select>
                                    <Select s={12} m={6}
                                        name="customSubscription"
                                        label="Custom Video Plan"
                                        icon={<Icon>assignment</Icon>}
                                        disabled={subscriptionType !== CUSTOM || isSubAdmin(props?.auth?.loggedInUser) ? true : cantEdit}
                                        value={customSubscription}
                                        onChange={onChange}
                                    >
                                        <option key={"none-option"} value="">
                                            Not Set
                                        </option>
                                        {getSelectOptions()}
                                    </Select>
                                    {/* <TextInput
                                        s={12} m={6}
                                        name="customSubscription"
                                        label="Custom Plan"
                                        icon="short_text"
                                        disabled={subscriptionType !== CUSTOM || cantEdit}
                                        value={customSubscription}
                                        onChange={onChange}
                                    /> */}
                                </Row>,
                                <Row>
                                    <Select s={12} m={6} disabled={cantEdit}
                                            icon={<Icon>help</Icon>}
                                            name="subtitles"
                                            label="Subtitles plan"
                                            value={subtitles}
                                            onChange={onChange}>
                                        <option value="0">
                                            Disable
                                        </option>
                                        <option value="1">
                                            Enable
                                        </option>
                                    </Select>
                                    <Col s={12} m={6}>
                                        <Button icon={<Icon>date_range</Icon>} style={{marginTop: '25px'}} tooltip="Billing Date"/>
                                        <DatePicker disabled={cantEdit}
                                                    options={{
                                                        autoClose: true,
                                                        format: "yyyy-mm-dd",
                                                        defaultDate: billingDate,
                                                        setDefaultDate: true
                                                    }}
                                                    onChange={(d) => setState(prev => ({...prev, billingDate: d}))}
                                                    style={{width: "25rem"}}/>
                                    </Col>
                                </Row>,
                                <Row>
                                    <Select s={12} m={6} disabled={isSubAdmin(props?.auth?.loggedInUser) ? true : (props?.auth?.loggedInUser.client_type === YT_HELPER_LEAD) ? false : cantEdit}
                                            icon={<Icon>help</Icon>}
                                            name="hasYoutubeHelper"
                                            label="Youtube Helper plan"
                                            value={hasYoutubeHelper}
                                            onChange={onChange}>
                                        <option value="0">
                                            Disable
                                        </option>
                                        <option value="1">
                                            Enable
                                        </option>
                                    </Select>
                                    <Select s={12} m={6} name="youtubeHelper"
                                            icon={<Icon>supervisor_account</Icon>}
                                            id="assignYtHelper"
                                            label="Youtube Helper"
                                            value={youtubeHelper[0] || "0"}
                                            disabled={hasYoutubeHelper === "0" || isSubAdmin(props?.auth?.loggedInUser) ? true : (props?.auth?.loggedInUser.client_type === YT_HELPER_LEAD) ? false : cantEdit}
                                            onChange={(e)=>onChangeYt(e,0)}>
                                        <option value="0">
                                            None
                                        </option>
                                        {getUserOptions(props.users.users, YT_HELPER)}
                                    </Select>
                                    <Select s={12} m={6} name="youtubeHelper"
                                            icon={<Icon>supervisor_account</Icon>}
                                            id="assignYtHelper"
                                            label="Youtube Helper"
                                            value={youtubeHelper[1] || "0"}
                                            disabled={hasYoutubeHelper === "0" || isSubAdmin(props?.auth?.loggedInUser) ? true : (props?.auth?.loggedInUser.client_type === YT_HELPER_LEAD) ? false : cantEdit}
                                            onChange={(e)=>onChangeYt(e,1)}>
                                        <option value="0">
                                            None
                                        </option>
                                        {getUserOptions(props.users.users, YT_HELPER)}
                                    </Select>
                                    <Select s={12} m={6}
                                        icon={<Icon>access_time</Icon>}
                                        id={randomNumber()}
                                        name="timezone"
                                        label="Timezone"
                                        value={state.timezone}
                                        onChange={onChange} >
                                        {
                                            TIMEZONE_LIST.map((item) => (
                                                <option value={item.value}>{item.label}</option>
                                            ))
                                        }
                                    </Select>
                                    </Row>,
                                    <Row style={{ marginTop: '20px' }}>
                                        <Col>
                                            <Icon left>email</Icon>
                                            <Col>
                                                <label style={{
                                                    fontSize: 'initial',
                                                    marginRight: '20px'
                                                }} >Email Preferences: </label>
                                                <Checkbox disabled={cantEdit}
                                                    id={CARD_MOVEMENT_EMAIL + "_checkbox"}
                                                    name={CARD_MOVEMENT_EMAIL}
                                                    label="Card Movement Email"
                                                    onChange={(e) => onEmailPreferencesChange(e)}
                                                    checked={Boolean(state.mailPreferences ? state.mailPreferences.card_movement : true)}
                                                    value="1" />
                                            </Col>
                                        </Col>

                                        <Col>
                                            <Checkbox disabled={cantEdit}
                                                id={CARD_COMMENT_EMAIL + "_checkbox"}
                                                name={CARD_COMMENT_EMAIL}
                                                label="Card Comment Email"
                                                onChange={(e) => onEmailPreferencesChange(e)}
                                                checked={Boolean(state.mailPreferences ? state.mailPreferences.card_comment : true)}
                                                value="1" />
                                        </Col>
                                        <Col>

                                            <Checkbox disabled={cantEdit}
                                                id={CARD_COMMENT_TAG_EMAIL + "_checkbox"}
                                                name={CARD_COMMENT_TAG_EMAIL}
                                                label="Card Comment Tag Email"
                                                onChange={(e) => onEmailPreferencesChange(e)}
                                                checked={Boolean(state.mailPreferences ? state.mailPreferences.card_comment_tag : true)}
                                                value="1" />
                                        </Col>
                                    </Row>,

                                    <Divider />,
                                <Row>
                                {
                                    !isCustomer(props?.auth?.loggedInUser) &&
                                        <div>
                                            <Col s={12}>
                                                <h4>For Team Notes</h4>
                                            </Col>
                                            <Textarea s={12} label="Team Notes" icon={<Icon>short_text</Icon>} name="teamNote" onChange={onChange} value={teamNote}/>
                                        </div>
                                }
                                </Row>,
                                <Divider/>,
                                <Row>
                                    <Textarea s={12} m={6} id="txtSMLink" name="socialMediaLink"
                                            label="What is your YouTube channel? What are your active social media accounts?"
                                            icon={<Icon>short_text</Icon>} value={state.socialMediaLink || ''} onChange={onChange} disabled={cantEdit}/>
                                    <Textarea s={12} m={6} id="txtAdditionalVideoInfo" name="additionalVideoInfo"
                                                label="Any other information would you like to give us?" icon={<Icon>short_text</Icon>}
                                                value={state.additionalVideoInfo || ''} onChange={onChange} disabled={cantEdit}/>
                                </Row>,
                                <Divider/>,
                                ]) : null
                        }
                        {
                            type === CUSTOMER && state.hasYoutubeHelper ==="1" ?
                            <Row>
                                <Divider/>
                                <Col s={12}>
                                    <h4>For YouTube Helper</h4>
                                </Col>
                                <Textarea s={12} m={6}
                                    id={randomNumber()}
                                    disabled={cantEdit}
                                    icon={<Icon>short_text</Icon>}
                                    name="channelName"
                                    label="Is this an existing channel? If so, What is your channel's name?"
                                    value={state.channelName}
                                    onChange={onChange}
                                />
                                <div className="thumbnail_row">
                                    {
                                        !showThumbnailsPre ?
                                        <div className="thumbnails-file-col">
                                            <div className="thumbnails-file-wrap">
                                                <label>
                                                    If you have a file you wish us to see as reference for your thumbnails, please upload here.
                                                </label>
                                                <TextInput 
                                                    type = "file" 
                                                    accept="image/*" 
                                                    disabled={cantEdit}
                                                    onChange = {e => 
                                                        setSelectedFile(URL.createObjectURL(e.target.files[0]))} 
                                                    label="Select File" />
                                                <div className ="thumbnail-prev-btn"> 
                                                <Button disabled={cantEdit} onClick={()=>{setSelectedFile(null); }} icon={<Icon left>clear</Icon>}>Cancel</Button>
                                                <Button icon={<Icon left>save</Icon>} style={{ width:'122px' }} onClick={()=>uploadFile()} disabled={!selectedFile || cantEdit}>Save</Button>
                                                </div>
                                            </div>
                                        </div>:null
                                    }
                                    {
                                        showThumbnailsPre ?
                                            <div className="thumbnails-file-col">
                                                <div className="thumbnail-file-wrap">
                                                    <label htmlFor="thumbnails-file-input">
                                                        <img src={selectedFile || state.refThumbnailsFile} alt="" style={{width: "100%", height: "auto", cursor: "pointer"}}/>
                                                    </label>
                                                    <input id="thumbnails-file-input" disabled={cantEdit} type="file" style={{display: "none"}} accept="image/*" onChange={e => 
                                                    setSelectedFile(URL.createObjectURL(e.target.files[0]))}/>
                                                    <div className ="thumbnail-prev-btn"> 
                                                        <Button disabled={cantEdit} onClick={()=>{setSelectedFile(null)}} icon={<Icon left>clear</Icon>}>Clear</Button>
                                                        <Button disabled={cantEdit} icon={<Icon left>save</Icon>} onClick={()=>uploadFile()}>Update</Button>
                                                    </div>
                                                </div>
                                            </div>:null
                                    }
                                    <div className="thumbnails_link_div">
                                        <label>Please provide links to videos that have thumbnails you like so we can refer to them.</label>
                                        <TextInput className="thumbnails_link_add_Btn" s={12} m={6}
                                            icon={<Button className="btn-primary" disabled={cantEdit}
                                                            onClick={()=>addPrevThumbnailsLink()}
                                                            ><Icon>add</Icon></Button>}
                                            name="prevThumbnailsLink" type="url" validate  disabled={cantEdit}
                                            // label="Please provide links to videos that have thumbnails you like so we can refer to them."
                                            onChange={e => onChangeLinks(e,0)} value={state.prevThumbnailsLink[0]}/>
                                        {makeLinks("prevThumbnailsLink", state.prevThumbnailsLink,removePrevThumbnailsLink )}
                                    </div>
                                    </div>
                                <Select s={12} m={6}
                                        id={randomNumber()}
                                        disabled={cantEdit}
                                        icon={<Icon>short_text</Icon>}
                                        name="photoPreference"
                                        label="Do you plan on providing us with photos to us or should we take screenshots from your video?"
                                        value={state.photoPreference}
                                        onChange={onChange} >
                                    <option value="" >Choose an option</option>
                                    <option value="provide_photos">I will provide photos</option>
                                    <option value="take_screenshots">Take screenshots</option>
                                </Select>
                                <Select s={12} m={6}
                                        id={randomNumber()}
                                        disabled={cantEdit}
                                        icon={<Icon>short_text</Icon>}
                                        name="titlePreference"
                                        label="How would you like your titles to appear?"
                                        value={state.titlePreference}
                                        onChange={onChange} >
                                    <option value="" >Choose an option</option>
                                    <option value="search_engine_optimised">Search engine optimised</option>
                                    <option value="clickbaity">Clickbaity</option>
                                    <option value="A_combo_of_both">A combo of both</option>
                                </Select>
                                <Select s={12} m={6}
                                        id={randomNumber()}
                                        disabled={cantEdit}
                                        icon={<Icon>short_text</Icon>}
                                        name="endCardPreference"
                                        label="Do you need an end card?"
                                        value={state.endCardPreference}
                                        onChange={onChange} >
                                    <option value="" >Choose an option</option>
                                    <option value="yes">Yes</option>
                                    <option value="no">No</option>
                                </Select>
                                <Textarea s={12} m={6}
                                    id={randomNumber()}
                                    disabled={cantEdit}
                                    icon={<Icon>short_text</Icon>}
                                    name="keywordList"
                                    label="Do you have a list of keywords? If so, please share them."
                                    value={state.keywordList}
                                    onChange={onChange}
                                />
                                <Select s={12} m={6}
                                        id={randomNumber()}
                                        disabled={cantEdit}
                                        icon={<Icon>short_text</Icon>}
                                        name="publicVideo"
                                        label="Do you want us to automatically set your videos to public when we upload?"
                                        value={state.publicVideo}
                                        onChange={onChange} >
                                    <option value="" >Choose an option</option>
                                    <option value="yes">Yes</option>
                                    <option value="no">No</option>
                                </Select>
                                <Textarea s={12} m={6}
                                    id={randomNumber()}
                                    disabled={cantEdit}
                                    icon={<Icon>short_text</Icon>}
                                    name="videoPublishingSchedules"
                                    label="Please provide your video publishing schedules."
                                    placeholder="Eg. Every Thursday, 10:00 AM, EST"
                                    value={state.videoPublishingSchedules}
                                    onChange={onChange}
                                />
                                <Textarea s={12} m={6}
                                    id={randomNumber()}
                                    disabled={cantEdit}
                                    icon={<Icon>short_text</Icon>}
                                    name="additionalThumbnailData"
                                    label="Anything else you'd like to add?"
                                    value={state.additionalThumbnailData}
                                    onChange={onChange}
                                />
                                <Col s={12} className="description_wrap">
                                        <label>Please provide link(s) to a sample description you like us to base our descriptions off of. You may provide the link to an actual video here. *</label>
                                        <TextInput className="description_link_add_Btn"
                                            icon={<Button  className="btn-primary" disabled={cantEdit}
                                                            onClick={()=>addDescriptionLink()}
                                                            ><Icon>add</Icon></Button>}
                                            name="descriptionLink" type="url" validate 
                                            disabled={cantEdit}
                                            // label="Please provide link(s) to a sample description you like us to base our descriptions off of. You may provide the link to an actual video here. *"
                                            onChange={e => onChangeLinks(e,0)} value={state.descriptionLink[0]}/>
                                        {makeLinks("descriptionLink", state.descriptionLink,removeDescriptionLink )}
                                    {/* </div>
                                    </div> */}
                                    </Col>

                            </Row> :null
                        }
                    </Container>
                </Tab>
                <Tab title="Billing">
                    <BillingTable data={billingData || []} />
                </Tab>
            </Tabs>
        </Card>
    );
});

function mapStateToProps({users, auth}) {
    return {users, auth};
}
