import React, {useEffect, useState} from "react";
import LoginPageCommon from "./LoginPageCommon";
import {connect} from "react-redux";
import * as actions from "../actions";
import {Link} from "react-router-dom";
import {Button, Col, Row, TextInput} from "react-materialize";
import {errorToast, infoToast, successToast} from "../api/Util";
import {axiosClient} from "../api/httpClient";

export default connect(mapStateToProps, actions)((props) => {

    const [email, setEmail] = useState('');
    const [showInstructions, setShowInstructions] = useState(false);
    const [loading, setLoading] = useState(false);

    const websiteName = props?.subDomain?.subDomainData?.name;

    useEffect(() => {
        setEmail('');
        setShowInstructions(false);
        setLoading(false);
    }, []);

    function validateForm() {
        return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email);
    }

    function handleSubmit() {
        infoToast("Loading");
        setLoading(true);
        axiosClient.post('/api/user/password-reset', { email })
            .then(() => {
                successToast('Password reset successful');
                setShowInstructions(true);
            })
            .catch((err) => {
                if (err.response?.status === 404) {
                    errorToast('Email does not exist');
                } else {
                    errorToast('Something went wrong, please try again');
                }
            }).finally(() => setLoading(false));
    }

    return (
        <LoginPageCommon>
            <h5>Welcome Back<br/>Pleasure To See You Again</h5>

            <div className={`outer-login-container ${websiteName}`}>
            <div className={`left-align inner-login-container ${websiteName}`}>
                    <h4 className="alignLabel">Reset Password</h4>

                    <Row>
                        <TextInput s={12}
                                id="email"
                                inputClassName="border-text-box"
                                placeholder="Email"
                                email validate autoFocus
                                value={email}
                                onChange={e => setEmail(e.target.value)} />
                    </Row>
                    <Row className="center-align">
                        <Col s={12}>
                            <Button className={`btn-primary round-button login-button ${websiteName}`} disabled={!validateForm() || loading}
                                    onClick={handleSubmit}>Reset Password</Button>
                        </Col>
                        <Col s={12} style={{marginTop: "2vh", textAlign: "center"}}>
                            Click here to <Link to='/login'>Sign In</Link>
                        </Col>
                    </Row>
                    {
                        showInstructions &&
                        <Row className="center-align">
                            <Col s={12}>
                                <p className="round-button green">We've reset your password and set an email containing further instructions</p>
                            </Col>
                        </Row>
                    }
                </div>
            </div>
        </LoginPageCommon>
    );
});

function mapStateToProps({subDomain}) {
    return {subDomain};
}
