import {axiosClient} from '../api/httpClient';

export const fetchCardsBasedOnSection = async (obj) => {
	try {
		const data = await axiosClient.post('/api/getCards', obj);
		return {
            status: true,
			data: data?.data,
		}
	} catch (error) {
		return {
            status: false,
			error: "Some Error Occured",
		}
	}
};

/**
 * Exports card data as CSV based on provided filter parameters.
 * 
 * @param {Object} filterParams - The parameters to filter the export.
 * @param {number} filterParams.limit - The maximum number of records to export.
 * @param {string} filterParams.customerId - The ID of the customer whose cards are to be exported.
 * @param {string} filterParams.startDate - The start date for filtering the cards.
 * @param {string} filterParams.endDate - The end date for filtering the cards.
 * @returns {Object} - An object containing the status and data or error message.
 */
export const exportCardBasedOnFilter = async (filterParams) => {
	try {
		const data = await axiosClient.get(`/api/exportCardAsCsv/`, {
			params: {
				limit: filterParams.limit,
				customerId: filterParams.customerId,
				start_date: filterParams.startDate,
				end_date: filterParams.endDate,
			},
		});
		return {
			status: true,
			data: data?.data
		};
	} catch (error) {
		return {
			status: false,
			error: "Some Error Occurred"
		};
	}
}

export const fetchCardsWithDueDate = async () => {
	try {
		const data = await axiosClient.get('/api/exportCards');
		return {
            status: true,
			data: data?.data,
		}
	} catch (error) {
		return {
            status: false,
			error: "Some Error Occured",
		}
	}
};

export const getCardsBasedOnDueDate = async (filterParams) => {
	try {
		const data = await axiosClient.get('/api/get-cards-by-due-date', {
            params: {
				viewAsCustomer: filterParams.viewAsCustomer,
				dueDate: filterParams.dueDate,
				viewAsQA: filterParams.viewAsQA,
				viewAsCardType: filterParams.viewAsCardType
			},
        });
		return {
            status: true,
			data: data?.data
		}
	} catch (error) {
		return {
            status: false,
			error: "Some Error Occured"
		}
	}
};

export const getCardsBasedOnTitle = async (title) => {
	try {
		const data = await axiosClient.get('/api/getCardsByTitle', {
            params: {
				title: title
			},
        });
		return {
            status: true,
			data: data?.data,
		}
	} catch (error) {
		return {
            status: false,
			error: "Some Error Occured",
		}
	}
};

export const saveNewQAOnCard = async (obj) => {
	try {
		const data = await axiosClient.post('/api/assignNewQA', obj);
		return {
            status: true,
			data: data?.data,
		}
	} catch (error) {
		return {
            status: false,
			error: "Some Error Occured",
		}
	}
};

export const addEventsForCard = (eventType, cardId) => {
	return axiosClient.get(`/api/add-card-event/${cardId}?type=${eventType}`) 
}

export const getUpdatedRatingCards = async (params) => {
	try {
			const data = await axiosClient.get("/api/getUpdatedRatingCards", {
					params: {
			startDate: params?.startDate,
			endDate: params?.endDate,
							limit: params?.limit,
							page: params?.index,
			selectedRating: params?.selectedRating
					},
			});
			return {
					status: true,
					data: data?.data,
			};
	} catch (error) {
			return {
					status: false,
					error: "Some Error Occurred",
			};
	}
};
