import React from "react";
import { Table } from "antd";

const TableView = ({ data }) => {
  const columns = [
    {
      title: "Card Title",
      dataIndex: "Card Title",
      key: "cardTitle",
    },
    {
      title: "Card Status",
      dataIndex: "Card Status",
      key: "cardStatus",
    },
    {
      title: "QA Name",
      dataIndex: "Qa Name",
      key: "qaName",
    },
    {
      title: "Customer Name",
      dataIndex: "Customer Name",
      key: "customerName",
    },
    {
      title: "Created Date",
      dataIndex: "created Date",
      key: "createdDate",
    },
  ];

  return <Table columns={columns} dataSource={data} rowKey="id" />;
};

export default TableView;
